import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TicketPromiseIconComponent } from './components/ticket-promise-icon/ticket-promise-icon.component';
import { TicketThumbnailComponent } from './components/ticket-thumbnail/ticket-thumbnail.component';

@NgModule({
	imports: [CommonModule],
	providers: [],
	declarations: [TicketThumbnailComponent, TicketPromiseIconComponent],
	exports: [TicketThumbnailComponent, TicketPromiseIconComponent],
})
export class SharedTicketModule {}
